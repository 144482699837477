import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "gatsby-image";

class AboutPage extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <Layout>
        <SEO title="A Little About Me" />
        <h1 class="no-margin-bottom">A Little About Me</h1>
        <p>
          <a
            title="Alex Brinkman - Résumé"
            href="/docs/Alex-Brinkman-Resume.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Résumé
          </a>
           |
          <Link to="/timeline">Carrer Timeline</Link>
           |
          <a
            title="Alex Brinkman - Github"
            href="https://github.com/alexbrinkman"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
           |
          <a
            title="Alex Brinkman - Personal"
            href="https://www.alexbrinkman.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            alexbrinkman.com
          </a>{" "}
          |
          <a
            title="Alex Brinkman - Twitter"
            href="http://twitter.com/alexbrinkman"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </p>
        <div style={{ width: "250px", float: "right" }}>
          <Image
            fluid={data.avatar.childImageSharp.fluid}
            alt="Alex Brinkman"
            className="me"
          />
        </div>
        <p>
          My name is Alex Brinkman and I write code for the web. Enlightened
          Pixel is my homebase for writing and thinking about the ever-changing
          web development industry. I'm a Ruby on Rails developer and fan. Much
          of what I write about centers around the Rails community and
          ecosystem.
        </p>

        <p>
          I'm currently the <strong>Director of Engineering</strong> at{" "}
          <a href="https://showami.com">Showami</a>.
        </p>

        <p>
          In the past I've run Enlightened Pixel as a development agency,
          helping clients build applications and identities on the web. More
          recently, I've been building and growing a couple of simple products -
          putting my skills to use for my own ideas.
        </p>

        <p>
          My personal site is at{" "}
          <a href="https://www.alexbrinkman.com">alexbrinkman.com</a> where I
          share more personal writing, reading, travel and photography.
        </p>
      </Layout>
    );
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/me.png/" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
